
import PromptYesNo from '@/components/modals/PromptYesNo.vue'
import ExpansionPanels from '@/components/utils/ExpansionPanels.vue'
import AppDeeplinkUpdater from '@/components/version/AppDeeplinkUpdater.vue'
import VersionDetails from '@/components/version/VersionDetails.vue'
import VersionEntry from '@/components/version/VersionEntry.vue'
import { AppConst } from '@/constants'

import { VersionConstants } from '@/store/modules/version/constants'
import { Platforms, Version } from '@/store/modules/version/types'
import { Namespace, StandardObject } from '@/store/types'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter, Mutation, State } from 'vuex-class'

const versionSubmissionSuccessMsg = 'New Version Submitted Successfully'

@Component({
  components: {
    'version-details': VersionDetails,
    'version-entry': VersionEntry,
    'app-deeplink-updater': AppDeeplinkUpdater,
    'expansion-panels': ExpansionPanels,
    'prompt-yes-no': PromptYesNo,
  },
})
export default class VersionsVue extends Vue {
  @State('latestVersion', { namespace: Namespace.Version })
  public latestVersion!: StandardObject
  @State('latestDesktopVersion', { namespace: Namespace.Version })
  public latestDesktopVersion!: StandardObject
  @State('latestIOSVersion', { namespace: Namespace.Version })
  public latestIOSVersion!: StandardObject
  @State('apiError', { namespace: Namespace.Utils })
  public apiError!: string
  @State('isSuperUser', { namespace: Namespace.App })
  public isSuperUser!: boolean

  @Getter('isPreparingApp', { namespace: Namespace.Utils })
  public isPreparingApp!: boolean

  @Mutation('updateVersion', { namespace: Namespace.Version })
  public updateVersion: any

  @Action('submitNewVersionEntry', { namespace: Namespace.Version })
  public submitNewVersionEntry: any
  @Action('createVersionPrompt', { namespace: Namespace.Version })
  public createVersionPrompt: any
  @Action('getLatestVersionUrl', { namespace: Namespace.Version })
  public getLatestVersionUrl: any

  private snackbarTimeoutID: ReturnType<typeof setTimeout> | undefined
  private snackbarTime: number = 3000
  private isJigStaff: boolean = false
  private snackbarVisible: boolean = false
  private snackbarMessage: string = versionSubmissionSuccessMsg
  private isNewEntryOpen: boolean = false
  private isAppDeeplinkUpdaterOpen: boolean = false
  private platforms: Platforms = VersionConstants.PlatformsOptions
  private applications: Array<VersionConstants.VersionDefaults> = VersionConstants.ApplicationItems
  private platform: VersionConstants.VersionDefaults = VersionConstants.VersionDefaults.DefaultPlatform
  private getVersionApplication: VersionConstants.VersionDefaults = VersionConstants.VersionDefaults.DefaultApplication
  private getVersionPlatform: string = VersionConstants.VersionDefaults.DefaultPlatform
  private loadingData: boolean = true
  private newSubmissionEntry: StandardObject = {}
  private dashboardEnv: string = process.env.VUE_APP_DASHBOARD_ENV || 'production'

  public $refs!: Vue['$refs'] & {
    versionEntry: VersionEntry
    submitNewEntryPrompt: PromptYesNo
  }

  protected async created() {
    const tenantTokens = this.$auth0.tenants
    this.isJigStaff = TenantHelpers.IsSuperUserOrJigStaff()

    if (!this.isJigStaff) {
      // Make sure only staff can see this page. Stop someone from trying to access this page using URL.
      await this.$router.push('/my-jigs')
    }

    if (this.isVersionEmpty(this.latestDesktopVersion)) {
      await this.retrieveVersionDetails()
    }

    if (this.apiError.indexOf(AppConst.Versions.versionErrorName) >= 0) {
      this.createVersionPrompt({ platform: 'Desktop' })
    }

    this.loadingData = false
  }

  private isVersionEmpty(target: any) {
    return !Object.keys(target).length || !Object.keys(target[this.getVersionApplication]).length
  }

  private closeSnackbar() {
    this.snackbarVisible = false
  }

  private resetEntryForm() {
    this.snackbarVisible = true

    clearTimeout(this.snackbarTimeoutID)

    this.snackbarTimeoutID = setTimeout(() => {
      this.snackbarVisible = false
    }, this.snackbarTime)
  }

  private openEntryForm() {
    this.isNewEntryOpen = true
  }

  private closeEntryForm() {
    this.isNewEntryOpen = false
  }

  private openAppDeeplinkUpdaterForm() {
    this.isAppDeeplinkUpdaterOpen = true
  }

  private closeAppDeeplinkUpdaterForm() {
    this.isAppDeeplinkUpdaterOpen = false
  }

  private updateLatestVersionValue(payload: any) {
    if (payload.Platform) {
      this.getVersionPlatform = payload.Platform
    }

    if (payload.Application) {
      this.getVersionApplication = payload.Application
    }

    this.getLatestVersion()
  }

  private cancelSubmission() {
    // Postpone submission to disable loading status.
    this.$refs.versionEntry.HandleSubmissionResults(false)
  }

  private confirmSubmitNewEntry(payload: Version) {
    this.newSubmissionEntry = payload
    this.$refs.submitNewEntryPrompt.ShowPrompt()
  }

  private async submitNewEntry(payload: Version) {
    const dataBody = payload || this.newSubmissionEntry
    const success = await this.submitNewVersionEntry(dataBody)

    this.$refs.versionEntry.HandleSubmissionResults(success)
    // Show the latest version of the platform that's been updated.
    this.getVersionPlatform = dataBody.Platform
    // Reset new entry variable
    this.newSubmissionEntry = {}
  }

  private async getLatestVersion() {
    switch (this.getVersionPlatform) {
      case VersionConstants.VersionDefaults.DefaultPlatform:
        if (this.isVersionEmpty(this.latestDesktopVersion)) {
          await this.retrieveVersionDetails()
        } else {
          this.updateVersion(this.latestDesktopVersion[this.getVersionApplication])
        }
        break
      default:
        if (this.isVersionEmpty(this.latestIOSVersion)) {
          await this.retrieveVersionDetails()
        } else {
          this.updateVersion(this.latestIOSVersion[this.getVersionApplication])
        }
        break
    }
  }

  private async retrieveVersionDetails() {
    this.loadingData = true

    await this.getLatestVersionUrl(`${this.getVersionApplication}/${this.getVersionPlatform}`)

    this.loadingData = false
  }
}
